import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { SharedPackageComponent } from './pages/shared-package/shared-package.component';

export const routes: Routes = [
  {
    path: '',
    title: 'Flexbenefits',
    component: HomeComponent,
  },
  {
    path: 'shared-package/:applicationId',
    title: 'Flexbenefits',
    component: SharedPackageComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];
