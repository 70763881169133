import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { AppRepository } from './shared/repositories/app.repository';
import { environment } from '../environments/environment';
import { RouterOutlet } from '@angular/router';
import { CustomerService } from './shared/services/customer.service';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { AgentRepository } from './shared/repositories/agent.repository';
import { IDistribution } from './shared/models/common.interface';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, MatButtonModule, FooterComponent, HeaderComponent, LoaderComponent, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  isEnvSettingsReady: boolean;
  isProd: boolean;
  primaryColor: string;
  constructor(
    private appRepository: AppRepository,
    private customerService: CustomerService,
    private agentRepository: AgentRepository
  ) {}

  ngOnInit() {
    this.setEnvSettings();
  }

  setEnvSettings() {
    const isLocalhost = location.hostname === 'localhost';
    if (!isLocalhost) {
      this.appRepository.getEnvSettings().subscribe(res => {
        this.isProd = res.production;
        environment.production = res.production;
        environment.name = res.name;
        environment.apiUrl = res.apiUrl;
        environment.stripePublishKey = res.stripePublishKey;
        this.isEnvSettingsReady = true;
        this.parseUrlForParams();
      });
    } else {
      this.isEnvSettingsReady = true;
      this.parseUrlForParams();
    }
  }

  parseUrlForParams() {
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const agentId = urlParams.get('agentId');
    const applicationId = urlParams.get('applicationId');
    const isAgentMode = urlParams.get('isAgentMode');
    const listBillId = urlParams.get('ListBill');

    if (agentId) {
      this.agentRepository.getAgentInfo(agentId).subscribe(res => {
        this.customerService.setAgent(res);
        this.setDistributionStyling(res.distribution);
      });
    } else {
      if (applicationId) {
        this.appRepository.getApplication(applicationId, false).subscribe(app => {
          this.agentRepository.getAgentInfo(app.agentId).subscribe(res => {
            this.customerService.setAgent(res);
            this.setDistributionStyling(res.distribution);
          });
        });
      } else {
        this.setDistributionStyling();
      }
    }
    if (applicationId) {
      this.customerService.setAppId(applicationId);
    }
    if (isAgentMode) {
      this.customerService.setAgentMode(true);
    }
    if (listBillId) {
      this.customerService.setListBillMode(true);
      this.customerService.setListBillId(listBillId);
      this.appRepository.getListBillData(listBillId).subscribe(res => {
        this.customerService.setListBill(res);
      });
    }
  }

  setDistributionStyling(distribution?: IDistribution) {
    if (distribution) {
      this.primaryColor = `#${distribution.colorPrimary}`;
      document.documentElement.style.setProperty(`--primary-color`, this.primaryColor);
      document.getElementById('contentBody').style.backgroundImage = `url(${distribution.backgroundUrl})`;
    } else {
      const defaultPrimaryColor = '#2E40E9';
      const defaultBackgroundUrl = './../assets/img/logo-bg.png';
      document.documentElement.style.setProperty(`--primary-color`, defaultPrimaryColor);
      document.getElementById('contentBody').style.backgroundImage = `url(${defaultBackgroundUrl})`;
    }
  }
}
