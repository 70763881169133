import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { IStepState } from '../../../../shared/models/common.interface';
import { Subject, takeUntil } from 'rxjs';
import { StepperService } from '../../../../shared/services/stepper.service';
import { MatIcon } from '@angular/material/icon';
import { ApplicationView, IPackage } from '../../../../shared/models/application.interface';
import { PlanStartDateSectionComponent } from '../../../../shared/components/plan-start-date-section/plan-start-date-section.component';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PackagePriceSummaryComponent } from '../../../../shared/components/package-price-summary/package-price-summary.component';
import { PaymentMethodTypeEnum } from '../../../../shared/enums/paymentMethodType.enum';
import { regexPatterns } from '../../../../shared/utils/regex';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { routingNumberValidation, startDateRangeValidation } from '../../../../shared/utils/custom-validators';
import { convertStringToLocalDate } from '../../../../shared/utils/date-format';
import { MatButton } from '@angular/material/button';
import { PriceFormatTransformPipe } from '../../../../shared/pipes/price-format.pipe';
import { AppRepository } from '../../../../shared/repositories/app.repository';
import { PaymentService } from '../../../../shared/services/payment.service';
import { CustomerService } from '../../../../shared/services/customer.service';
import { InsuranceDocumentTypeEnum } from '../../../../shared/enums/insuranceDocumentType.enum';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'app-purchase-step',
  standalone: true,
  imports: [
    MatIcon,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatRadioModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    PlanStartDateSectionComponent,
    PackagePriceSummaryComponent,
    MatButton,
    PriceFormatTransformPipe,
    MatCheckbox,
  ],
  templateUrl: './purchase-step.component.html',
  styleUrl: './purchase-step.component.scss',
})
export class PurchaseStepComponent implements OnInit, OnDestroy {
  @Input() set insuranceStartDate(value: string) {
    if (value) {
      this.startDateControl = new FormControl(convertStringToLocalDate(value), [
        Validators.required,
        startDateRangeValidation(),
      ]);
    }
  }
  @Input() set selectedPackage(value: IPackage) {
    if (value) {
      this.selectedPackageData = value;
    }
  }
  @Input() set quote(value: ApplicationView) {
    if (value) {
      this.quoteData = value;
      if (this.achPaymentForm) {
        this.achPaymentForm.get('email').setValue(this.quoteData.contactInfo.email);
        if (this.quoteData?.payment?.ach) {
          this.achPaymentForm.patchValue(this.quoteData.payment.ach);
          this.paymentMethodControl.setValue(this.paymentMethodType.ACH);
          this.isPayedByACH = true;
        } else if (this.quoteData?.payment?.stripeCustomerId) {
          this.isPayedByCard = true;
        }
      }
      if (this.quoteData.payment && !this.quoteData?.payment?.ach && !this.quoteData?.payment?.stripeCustomerId) {
        this.isPayedByListBill = true;
      }
      if (!this.quoteData?.signatures?.length) {
        this.isStripeReady = false;
        this.paymentService.destroyStripe();
        this.paymentMethodControl?.setValue(this.paymentMethodType.CARD);
        this.stepperService.unCompleteStep(4);
      }
    }
  }
  @Input() currentPaymentMethod: PaymentMethodTypeEnum;
  @Output() changeStartDateEvent: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() paymentSubmitEvent: EventEmitter<ApplicationView> = new EventEmitter<ApplicationView>();
  @Output() paymentMethodChangeEvent: EventEmitter<{
    method: PaymentMethodTypeEnum;
    quote: ApplicationView;
    selectedPackageId: string;
  }> = new EventEmitter<{ method: PaymentMethodTypeEnum; quote: ApplicationView; selectedPackageId: string }>();
  paymentMethodType = PaymentMethodTypeEnum;
  stepState: IStepState;
  selectedPackageData: IPackage;
  startDateControl: FormControl;
  paymentMethodControl: FormControl;
  achPaymentForm: FormGroup;
  quoteData: ApplicationView;
  isPayedByACH: boolean = false;
  isPayedByCard: boolean = false;
  isPayedByListBill: boolean = false;
  isStripeReady: boolean = false;
  isListBillMode: boolean;
  destroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private stepperService: StepperService,
    private appRepository: AppRepository,
    private paymentService: PaymentService,
    private customerService: CustomerService
  ) {}

  ngOnInit(): void {
    this.isListBillMode = this.customerService.isListBillModeActive();
    if (!this.isListBillMode) {
      this.paymentMethodControl = new FormControl(
        this.isListBillMode ? this.paymentMethodType.ACH : this.paymentMethodType.CARD
      );
      this.buildForm();
    }
    this.setStepState();
  }

  setStepState() {
    this.stepperService.stepperStateSubject$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.stepState = res[4];
    });
  }

  buildForm(): void {
    this.achPaymentForm = this.formBuilder.group({
      email: new FormControl(
        { value: this.quoteData?.contactInfo?.email, disabled: true },
        Validators.pattern(regexPatterns.email)
      ),
      accountHolderName: new FormControl(null, [Validators.required, Validators.pattern(regexPatterns.name)]),
      bankName: new FormControl(null, [Validators.required, Validators.pattern(regexPatterns.bankName)]),
      routingNumber: new FormControl(null, [Validators.required, routingNumberValidation()]),
      accountNumber: new FormControl(null, [Validators.required, Validators.pattern(regexPatterns.onlyNumbers)]),
      achPaymentDelay: new FormControl(false),
    });
  }

  updateStartDate(newDate: Date): void {
    this.changeStartDateEvent.emit(newDate);
    this.stepperService.openStep(3);
  }

  bankPaymentClick() {
    this.appRepository
      .payByACH(this.quoteData.id, { ...this.achPaymentForm.value, email: this.quoteData?.contactInfo?.email })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isPayedByACH = true;
        this.stepperService.completeStep(4);
        this.paymentSubmitEvent.emit(this.quoteData);
      });
  }

  onPaymentStepOpen() {
    if (!this.isListBillMode && !this.isStripeReady && !this.quoteData?.payment) {
      this.appRepository
        .creditCardPaymentInit(this.quoteData.id)
        .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          this.paymentService.initStripe(res).then(() => {
            this.isStripeReady = true;
          });
        });
    }
  }

  onPaymentMethodChange(method: PaymentMethodTypeEnum) {
    this.paymentMethodChangeEvent.emit({
      method: method,
      quote: this.quoteData,
      selectedPackageId: this.selectedPackageData.id,
    });
  }

  billPaymentClick() {
    this.appRepository
      .payByListBill(this.quoteData.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isPayedByListBill = true;
        this.stepperService.completeStep(4);
        this.paymentSubmitEvent.emit(this.quoteData);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected readonly insuranceDocumentType = InsuranceDocumentTypeEnum;
}
