import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatError, MatFormField, MatHint, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { IStepState } from '../../../../shared/models/common.interface';
import { StepperService } from '../../../../shared/services/stepper.service';
import { ApplicationView, IPackage } from '../../../../shared/models/application.interface';
import { ExpandableTextComponent } from '../../../../shared/components/expandable-text/expandable-text.component';
import { CommonModule, NgClass, NgTemplateOutlet } from '@angular/common';
import { PackagePriceSummaryComponent } from '../../../../shared/components/package-price-summary/package-price-summary.component';
import { regexPatterns } from '../../../../shared/utils/regex';
import { PriceFormatTransformPipe } from '../../../../shared/pipes/price-format.pipe';
import { PlanStartDateSectionComponent } from '../../../../shared/components/plan-start-date-section/plan-start-date-section.component';
import { startDateRangeValidation } from '../../../../shared/utils/custom-validators';
import { PrintPageHeaderComponent } from '../../../../shared/components/print-page-header/print-page-header.component';
import { DomPortalOutlet, PortalOutlet, TemplatePortal } from '@angular/cdk/portal';
import { PrintService } from '../../../../shared/services/print.service';
import { ModalDialogComponent } from '../../../../shared/components/modal-dialog/modal-dialog.component';
import { ModalDialogData } from '../../../../shared/models/modal-dialog.interface';
import { MatDialog, MatDialogActions, MatDialogRef } from '@angular/material/dialog';
import { getStateByZipCode } from '../../../../shared/utils/state-by-zipcode';
import { convertStringToLocalDate } from '../../../../shared/utils/date-format';
import { PackageContentComponent } from '../../../../shared/components/package-content/package-content.component';
import { AppRepository } from '../../../../shared/repositories/app.repository';
import { GlobalMessagesService } from '../../../../shared/services/global-messages.service';
import { CustomerService } from '../../../../shared/services/customer.service';
import { PaymentMethodTypeEnum } from '../../../../shared/enums/paymentMethodType.enum';

@Component({
  selector: 'app-benefits-step',
  standalone: true,
  imports: [
    MatButton,
    MatButtonToggle,
    MatButtonToggleGroup,
    MatError,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatFormField,
    MatHint,
    MatIcon,
    MatInput,
    MatLabel,
    MatSuffix,
    ReactiveFormsModule,
    CommonModule,
    ExpandableTextComponent,
    MatIconButton,
    NgClass,
    PackagePriceSummaryComponent,
    PlanStartDateSectionComponent,
    PriceFormatTransformPipe,
    NgTemplateOutlet,
    PrintPageHeaderComponent,
    ModalDialogComponent,
    PackageContentComponent,
    MatDialogActions,
  ],
  templateUrl: './benefits-step.component.html',
  styleUrl: './benefits-step.component.scss',
})
export class BenefitsStepComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('printContent') printContentRef: TemplateRef<any>;
  @ViewChild('printIframe') printIframeRef: ElementRef;
  @ViewChild('sharePackageDialog') sharePackageDialog: TemplateRef<any>;
  @Input() set selectedPackage(value: IPackage) {
    if (value) {
      this.selectedPackageData = value;
    }
  }
  @Input() set quote(value: ApplicationView) {
    if (value) {
      this.quoteData = value;
      this.currentState = getStateByZipCode(+value.contactInfo.zipCode);
    }
  }
  @Input() isQuoteSaved: boolean;
  @Input() currentPaymentMethod: PaymentMethodTypeEnum;
  @Input() set insuranceStartDate(value: string) {
    if (value) {
      this.startDateControl = new FormControl(convertStringToLocalDate(value), [
        Validators.required,
        startDateRangeValidation(),
      ]);
    }
  }
  @Output() benefitLevelChangeEvent: EventEmitter<IPackage> = new EventEmitter<IPackage>();
  @Output() goToEnrollmentStepEvent: EventEmitter<{ email: string; startDate: string }> = new EventEmitter<{
    email: string;
    startDate: string;
  }>();
  @Output() updateStartDateEvent: EventEmitter<Date> = new EventEmitter<Date>();
  saveQuoteEmailControl: FormControl;
  startDateControl: FormControl;
  selectedPackageData: IPackage;
  stepState: IStepState;
  currentState: string;
  quoteData: ApplicationView;
  sharePackageDialogRef: MatDialogRef<ModalDialogComponent>;
  sharePackageEmailControl: FormControl;
  isAgentMode: boolean;
  destroy$ = new Subject<void>();
  private printIframe;
  private portalHost: PortalOutlet;
  constructor(
    private stepperService: StepperService,
    private viewContainerRef: ViewContainerRef,
    private printService: PrintService,
    private dialog: MatDialog,
    private appRepository: AppRepository,
    private messageService: GlobalMessagesService,
    private customerService: CustomerService
  ) {}

  ngOnInit() {
    this.setStepState();
    if (!this.isQuoteSaved) {
      this.saveQuoteEmailControl = new FormControl('');
      this.saveQuoteEmailControl.addValidators([Validators.required, Validators.pattern(regexPatterns.email)]);
    }
    this.isAgentMode = this.customerService.isAgentModeActive();
  }

  ngAfterViewInit() {
    this.preparePrintIframe();
  }

  setStepState() {
    this.stepperService.stepperStateSubject$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.stepState = res[2];
    });
  }

  onLevelOptionChange() {
    this.benefitLevelChangeEvent.emit(this.selectedPackageData);
  }

  goToEnrollmentStep() {
    this.goToEnrollmentStepEvent.emit({
      email: this.saveQuoteEmailControl?.value,
      startDate: this.startDateControl?.value,
    });
  }

  onPrintPackageClick(e) {
    e.stopPropagation();
    this.portalHost = new DomPortalOutlet(this.printIframe.contentDocument.body);
    const portal = new TemplatePortal(this.printContentRef, this.viewContainerRef, {
      selectedPackageData: this.selectedPackageData,
    });
    this.portalHost.attach(portal);
    this.printIframe.contentWindow.onafterprint = () => {
      this.printIframe.contentDocument.body.innerHTML = '';
    };
    this.printService.print(this.printIframe);
  }

  private preparePrintIframe() {
    this.printIframe = this.printIframeRef.nativeElement;
    this.printService.attachStyles(this.printIframe.contentWindow);
  }

  onSharePackageClick(): void {
    this.sharePackageEmailControl = new FormControl(null, [
      Validators.required,
      Validators.pattern(regexPatterns.email),
    ]);
    const modalDialogData: ModalDialogData = {
      adaptivePosition: true,
      title: 'Share Package',
      hideCloseBtn: false,
      hasContent: true,
      hideActions: false,
      showConfirmBtn: true,
      showCancelBtn: true,
      confirmActionText: 'Share Package',
      cancelActionText: 'Cancel',
      onConfirmClick: this.onSharePackageConfirm.bind(this),
    };

    this.sharePackageDialogRef = this.dialog.open(this.sharePackageDialog, {
      data: modalDialogData,
      width: '430px',
      autoFocus: false,
      disableClose: true,
    });

    this.sharePackageDialogRef.afterClosed().subscribe(() => {
      this.sharePackageEmailControl = null;
    });
  }

  onSharePackageConfirm() {
    if (this.sharePackageEmailControl.valid) {
      this.sharePackageDialogRef.close();
      this.appRepository
        .sharePackageLinkByMail(this.quoteData.id, this.sharePackageEmailControl.value)
        .subscribe(() => {
          this.messageService.show('success', 'Email was successfully sent.');
        });
    } else {
      this.sharePackageEmailControl.markAsTouched();
      this.sharePackageEmailControl.updateValueAndValidity();
    }
  }

  updateStartDate(newStartDate: Date): void {
    if (this.isQuoteSaved) {
      this.updateStartDateEvent.emit(newStartDate);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
