<mat-expansion-panel [disabled]="!stepState.isEnabled" hideToggle="true" [(expanded)]="stepState.isOpened">
  <mat-expansion-panel-header>
    <div class="container">
      <div class="d-flex align-items-center justify-content-between">
        <mat-panel-title>
          <span>Step 1</span>
          <span class="dot-divider"></span>
          <span>{{ stepState.stepName }}</span>
        </mat-panel-title>
        @if (stepState.isCompleted && isQuoteSaved) {
          <div class="complete-icon">
            <mat-icon>check</mat-icon>
          </div>
        } @else if (stepState.isCompleted && !isQuoteSaved) {
          <a class="save-link" href="javascript:void(0)" (click)="onSaveLinkClick($event)">Not Saved</a>
        }
      </div>
      @if (stepState.isOpened && !stepState.isCompleted) {
        <div class="step-description">Please provide some information used to generate your quote.</div>
      }
      @if (stepState.isCompleted) {
        <div class="d-flex align-items-center pt-1 step-completeness-info">
          <mat-icon class="me-2">account_circle</mat-icon>
          <div>
            <div>
              {{ 1 + quotingData?.customers?.length }}
              {{ quotingData?.customers?.length ? 'people' : 'person' }} people in {{ currentState }}
            </div>
            <div>
              from <span class="text-bolder">{{ quotingData?.startDate }}</span>
            </div>
          </div>
        </div>
      }
    </div>
  </mat-expansion-panel-header>
  <div class="wrap-quote-form container pt-4 pb-4">
    <div class="quote-form d-flex flex-column mb-4" [formGroup]="quoteForm">
      <div class="d-flex justify-content-between align-items-start section-required-info">
        <mat-form-field class="start-date-field" appearance="outline">
          <mat-label>Insurance Start Date (Effective date)</mat-label>
          <input
            matInput
            appDateInputMask
            [matDatepickerFilter]="isListBillMode ? billFilter : rangeFilter"
            [matDatepicker]="startDatePicker"
            formControlName="startDate" />

          @if (quoteForm.controls.startDate.errors?.required) {
            <mat-error>Start Date is required.</mat-error>
          }
          @if (quoteForm.controls.startDate.errors?.startDateRangeError) {
            <mat-error
              >The allowed Start Date range is from the <span class="text-bolder">Next Day to +62 days</span> from the
              actual date.
            </mat-error>
          }

          <mat-datepicker-toggle matIconSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
          <mat-hint>MM/DD/YYYY</mat-hint>
        </mat-form-field>

        <mat-form-field class="mb-2 my-location-field" appearance="outline">
          <mat-label>ZIP Code</mat-label>
          <input type="text" matInput formControlName="zipCode" class="my-location-input" />
          <mat-icon class="my-location-icon">my_location</mat-icon>

          @if (quoteForm.controls.zipCode.errors?.required) {
            <mat-error>ZIP Code is required.</mat-error>
          }
          @if (quoteForm.controls.zipCode.errors?.zipCodeError) {
            <mat-error>Invalid ZIP Code.</mat-error>
          }
        </mat-form-field>

        <mat-form-field appearance="outline" class="birth-date-field">
          <mat-label>Date of Birth</mat-label>
          <input
            matInput
            appDateInputMask
            [matDatepickerFilter]="holderAgeFilter"
            [matDatepicker]="birthDatePicker"
            formControlName="dateOfBirth" />

          @if (quoteForm.controls.dateOfBirth.errors?.required) {
            <mat-error>Date of Birth is required.</mat-error>
          }

          @if (quoteForm.controls.dateOfBirth.errors?.ageLow) {
            <mat-error>Only person over 18 years is allowed.</mat-error>
          }
          @if (quoteForm.controls.dateOfBirth.errors?.ageHigh) {
            <mat-error>Only person under 72 years is allowed.</mat-error>
          }
          <mat-datepicker-toggle matIconSuffix [for]="birthDatePicker"></mat-datepicker-toggle>
          <mat-datepicker
            #birthDatePicker
            [startAt]="quoteForm.controls.dateOfBirth?.value ? null : holderAgeStartDate">
          </mat-datepicker>
          <mat-hint>MM/DD/YYYY</mat-hint>
        </mat-form-field>

        <mat-button-toggle-group formControlName="genderType" class="toggle">
          <mat-button-toggle [value]="genderType.MALE" class="flex-fill">
            <mat-icon [class.visible]="quoteForm.get('genderType').value === 1">check</mat-icon>
            Male
          </mat-button-toggle>
          <mat-button-toggle [value]="genderType.FEMALE" class="flex-fill">
            <mat-icon [class.visible]="quoteForm.get('genderType').value === 2">check</mat-icon>
            Female
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>

      @if (quoteForm.get('customers')?.length) {
        <div class="customers-controls-group d-flex flex-column align-items-start" [formGroup]="customersFormArray">
          @for (customer of customersFormArray.controls; track customer; let index = $index) {
            <app-customer-item
              type="entryData"
              [isReadOnly]="serverData?.isPaymentSubmitted"
              [customer]="customer"
              [index]="index"
              [childIndex]="getChildIndex(customer)"
              (removeControl)="removeControl($event)">
            </app-customer-item>
          }
        </div>
      }
      @if (!serverData?.isPaymentSubmitted) {
        <div class="actions-section">
          @if (!isSpouseAdded) {
            <button
              id="addSpouseBtn"
              mat-button
              color="primary"
              class="btn-lg"
              type="button"
              (click)="addCustomers(customerType.SPOUSE)">
              <span class="add-btn d-flex align-items-center">
                <mat-icon>add</mat-icon>
                <span>Add Spouse</span>
              </span>
            </button>
          }

          @if (childrenFormArray?.length < 10) {
            <button
              id="addChildBtn"
              mat-button
              color="primary"
              class="btn-lg"
              type="button"
              (click)="addCustomers(customerType.CHILD)">
              <span class="add-btn d-flex align-items-center">
                <mat-icon>add</mat-icon>
                <span>Add Child</span>
              </span>
            </button>
          }
        </div>
      }
      <div class="mb-5">
        @if (isQuoteSaved && !isEmailEditMode) {
          <div class="ps-4 section-saved-quote">
            <div class="mb-1">Your Quote is saved</div>
            <div class="section-current-email">
              <span class="mb-1">You'll get the link to this quote on the</span>
              <div class="d-flex flex-wrap section-change-email">
                <span class="me-3 text-bolder current-email">{{ quotingData?.email }}</span>
                @if (!serverData?.isPaymentSubmitted) {
                  <a class="app-link" href="javascript:void(0)" (click)="onEmailChangeClick()">Change</a>
                }
              </div>
            </div>
          </div>
        } @else {
          <div class="mb-4">Do you want to save Quote?</div>
          <div class="d-flex section-save-quote">
            <mat-form-field class="user-email-field me-4" appearance="outline">
              @if (isAgentMode) {
                <mat-label>Enter your client's email (optional)</mat-label>
              } @else {
                <mat-label>Enter your email (optional)</mat-label>
              }
              <input type="text" matInput formControlName="email" />
              @if (quoteForm.controls.email.errors?.pattern) {
                <mat-error>Email format does not match.</mat-error>
              }
              @if (isAgentMode) {
                <mat-hint>This quote will be saved to your agent dashboard</mat-hint>
              } @else {
                <mat-hint>We’ll email you a link to this page for whenever you need it!.</mat-hint>
              }
            </mat-form-field>
            <button
              mat-stroked-button
              color="primary"
              class="save-quote-btn d-flex align-items-center"
              type="button"
              [disabled]="quoteForm.invalid || !quoteForm.get('email').value || quoteForm.get('email').invalid"
              (click)="saveQuoteAndSendLink()">
              <mat-icon>save</mat-icon>
              Save My Quote
            </button>
          </div>
        }
      </div>
      @if (!serverData?.isPaymentSubmitted) {
        <button
          mat-raised-button
          color="primary"
          class="btn-lg next-btn"
          type="button"
          [disabled]="quoteForm.invalid"
          (click)="goToPersonalizedPlans()">
          View Products
        </button>
      }
    </div>
  </div>
</mat-expansion-panel>

<ng-template #saveQuoteDialog>
  <app-modal-dialog id="saveQuoteDialog">
    <div class="mb-3">We’ll email you a link to your quote for whenever you need it.</div>
    <mat-form-field class="w-100" appearance="outline">
      @if (isAgentMode) {
        <mat-label>Enter your client's email</mat-label>
      } @else {
        <mat-label>Enter your email</mat-label>
      }
      <input type="text" matInput [formControl]="saveQuoteEmailControl" />
      @if (saveQuoteEmailControl.errors?.required) {
        <mat-error>Email is required</mat-error>
      }
      @if (saveQuoteEmailControl.errors?.pattern) {
        <mat-error>Email format does not match.</mat-error>
      }
    </mat-form-field>
  </app-modal-dialog>
</ng-template>

<ng-template #changeDataDialog>
  <app-modal-dialog id="changeDataDialog">
    <div class="mb-3">Information on this screen is the basic one for creation Quote prices.</div>
  </app-modal-dialog>
</ng-template>
