import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { CustomerTypeEnum } from '../../../../shared/enums/customerType.enum';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { ApplicationView, MappedQuotingView, MappedQuoting } from '../../../../shared/models/application.interface';
import { GenderTypeEnum } from '../../../../shared/enums/genderType.enum';
import { CustomerService } from '../../../../shared/services/customer.service';
import { CommonModule, DatePipe } from '@angular/common';
import { ISendLinkData, IStepState } from '../../../../shared/models/common.interface';
import { StepperService } from '../../../../shared/services/stepper.service';
import { Subject, Subscription, filter, takeUntil } from 'rxjs';
import { regexPatterns } from '../../../../shared/utils/regex';
import { cloneDeep, isEqual } from 'lodash-es';
import { CustomerItemComponent } from '../../../../shared/components/customer-item/customer-item.component';
import {
  dependentAgeRestrictionValidation,
  insuranceHolderAgeRestrictionValidation,
  insuranceSpouseAgeRestrictionValidation,
  startDateRangeValidation,
} from '../../../../shared/utils/custom-validators';
import { zipCodeValidation } from '../../../../shared/utils/zip-code-validation';
import { ModalDialogComponent } from '../../../../shared/components/modal-dialog/modal-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalDialogData } from '../../../../shared/models/modal-dialog.interface';
import { AppService } from '../../../../shared/services/app.service';
import { getStateByZipCode } from '../../../../shared/utils/state-by-zipcode';
import { convertStringToLocalDate, getTimeZoneOffsetMinutes } from '../../../../shared/utils/date-format';
import { DateInputFormatDirective } from '../../../../shared/directives/date-input-format.directive';

@Component({
  selector: 'app-quote-step',
  standalone: true,
  imports: [
    CommonModule,
    CustomerItemComponent,
    ReactiveFormsModule,
    MatIcon,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonToggleModule,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    ModalDialogComponent,
    DateInputFormatDirective,
  ],
  templateUrl: './quote-step.component.html',
  styleUrl: './quote-step.component.scss',
  providers: [provideNativeDateAdapter(), DatePipe],
})
export class QuoteStepComponent implements OnInit, OnDestroy {
  @ViewChild('saveQuoteDialog') saveQuoteDialog: TemplateRef<any>;
  @ViewChild('changeDataDialog') changeDataDialog: TemplateRef<any>;
  saveQuoteDialogRef: MatDialogRef<ModalDialogComponent>;
  changeDataDialogRef: MatDialogRef<ModalDialogComponent>;
  @Input() set quote(value: ApplicationView) {
    if (value) {
      if (!this.quoteForm) {
        this.buildForm();
      }
      this.serverData = value;
      this.quotingData = this.appService.getMappedQuoting(value, false);
      this.fillForm(this.quotingData);
      if (value.isPaymentSubmitted) {
        this.quoteForm.disable();
      }
    }
  }
  @Input() isQuoteSaved: boolean;
  @Output() saveQuoteEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveQuoteWithSendLinkEvent: EventEmitter<ISendLinkData> = new EventEmitter<ISendLinkData>();
  @Output() unCompleteAllStepsEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() goToPackagesStepEvent: EventEmitter<ApplicationView> = new EventEmitter<ApplicationView>();
  saveQuoteEmailControl: FormControl;
  quotingData: MappedQuotingView;
  serverData: ApplicationView;
  quoteForm: FormGroup;
  customerType = CustomerTypeEnum;
  genderType = GenderTypeEnum;
  rangeFilter = this.getRangeFilter.bind(this);
  billFilter = this.getBillFilter.bind(this);
  holderAgeFilter = this.getHolderAgeFilter.bind(this);
  holderAgeStartDate = this.getHolderAgeStartDate();
  stepState: IStepState;
  stepperState: IStepState[];
  isEmailEditMode: boolean = false;
  isAgentMode: boolean;
  isListBillMode: boolean;
  formChangeSubscription$: Subscription;
  currentFormValue: MappedQuoting;
  destroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private datePipe: DatePipe,
    private stepperService: StepperService,
    private dialog: MatDialog,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.isAgentMode = this.customerService.isAgentModeActive();
    this.isListBillMode = this.customerService.isListBillModeActive();
    this.buildForm();
    this.setStepState();
    this.initListeners();
  }

  initListeners() {
    this.appService.saveQuoteWithSendLinkSubject$
      .pipe(
        filter(res => !!res),
        takeUntil(this.destroy$)
      )
      .subscribe(res => {
        if (res?.email) {
          if (this.quoteForm.valid) {
            this.quoteForm.get('email').setValue(res.email, { emitEvent: false });
            if (res?.startDate) {
              this.quoteForm.get('startDate').setValue(res.startDate, { emitEvent: false });
            }
            this.saveQuoteAndSendLink();
          }
        } else {
          this.stepperService.openStep(0);
          this.quoteForm.markAsTouched();
          this.quoteForm.updateValueAndValidity();
        }
      });
  }

  setStepState() {
    this.stepperService.stepperStateSubject$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.stepState = res[0];
      this.stepperState = res;
    });
  }

  get currentState(): string {
    if (this.quoteForm.get('zipCode').invalid) {
      return '';
    } else {
      return getStateByZipCode(this.quoteForm.get('zipCode').value);
    }
  }

  buildForm(): void {
    const tomorrow = this.getTomorrowDate();
    this.quoteForm = this.formBuilder.group({
      startDate: new FormControl(this.isListBillMode ? this.appService.getFirstDayOfNextMonth() : tomorrow, [
        Validators.required,
        startDateRangeValidation(),
      ]),
      zipCode: new FormControl('', [Validators.required, zipCodeValidation()]),
      dateOfBirth: new FormControl(null, [Validators.required, insuranceHolderAgeRestrictionValidation()]),
      genderType: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.pattern(regexPatterns.email)),
    });
  }

  fillForm(value: MappedQuotingView): void {
    this.quoteForm.patchValue(value, { emitEvent: false });
    this.quoteForm.get('startDate').setValue(convertStringToLocalDate(value.startDate), { emitEvent: false });
    this.quoteForm.get('dateOfBirth').setValue(convertStringToLocalDate(value.dateOfBirth), { emitEvent: false });
    if (value.customers) {
      this.fillCustomersFormArray(value);
    }
    if (!this.formChangeSubscription$) {
      this.currentFormValue = this.quoteForm.value;
      this.formChangeSubscription$ = this.quoteForm.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(changedFormValue => {
          const prevFormValue = cloneDeep(this.currentFormValue);
          const hasDif = !isEqual(prevFormValue, changedFormValue);
          const changedFieldName = this.appService.getChangedFieldName(prevFormValue, changedFormValue);
          if (hasDif && this.stepperState[1].isEnabled && changedFieldName !== 'email') {
            this.showChangeDataDialog(prevFormValue, changedFormValue);
            this.serverData.selectedPackageId = null;
            this.serverData.packages = [];
          }
        });
    }
  }

  fillCustomersFormArray(value: MappedQuoting): void {
    if (this.quoteForm.get('customers')) {
      this.quoteForm.removeControl('customers', { emitEvent: false });
    }
    this.quoteForm.addControl('customers', new FormArray([]), { emitEvent: false });
    value.customers
      .sort((a, b) => a.sequence - b.sequence)
      .forEach(customer => {
        const newCustomer = this.getNewCustomerControl(customer.customerType ? customer.customerType : null);
        newCustomer.patchValue(customer, { emitEvent: false });
        newCustomer.get('dateOfBirth').setValue(convertStringToLocalDate(customer.dateOfBirth), { emitEvent: false });
        this.customersFormArray.push(newCustomer, { emitEvent: false });
      });
  }

  addCustomers(customerType: number): void {
    const newCustomer = this.getNewCustomerControl(customerType);

    if (this.quoteForm.contains('customers')) {
      (this.quoteForm.get('customers') as FormArray).push(newCustomer);
    } else {
      this.quoteForm.addControl('customers', new FormArray([newCustomer]));
    }
  }

  getNewCustomerControl(customerType: number) {
    const dateOfBirthValidators =
      customerType === CustomerTypeEnum.CHILD
        ? [Validators.required, dependentAgeRestrictionValidation()]
        : [Validators.required, insuranceSpouseAgeRestrictionValidation()];

    return this.formBuilder.group({
      dateOfBirth: new FormControl(null, dateOfBirthValidators),
      genderType: new FormControl(null, Validators.required),
      customerType: new FormControl(customerType, Validators.required),
    });
  }

  get customersFormArray(): FormArray {
    return this.quoteForm.get('customers') as FormArray;
  }

  get childrenFormArray(): any {
    return (this.quoteForm.get('customers') as FormArray)?.controls.filter(
      item => item.get('customerType').value === CustomerTypeEnum.CHILD
    );
  }

  get isSpouseAdded(): boolean {
    return !!(this.quoteForm.get('customers') as FormArray)?.controls.filter(
      item => item.get('customerType').value === CustomerTypeEnum.SPOUSE
    ).length;
  }

  removeControl(index: number): void {
    this.customersFormArray.removeAt(index);
    this.quoteForm.markAsTouched();
  }

  getChildIndex(childCustomer: AbstractControl): number {
    return this.childrenFormArray.indexOf(childCustomer);
  }

  getTomorrowDate(): Date {
    return new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  }

  getRangeFilter(d: Date | null): boolean {
    const day = d || new Date();
    const today = new Date(new Date().getTime());
    const limitDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 62);

    return day > today && day <= limitDay;
  }

  getBillFilter(d: Date | null): boolean {
    if (!d) return false;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const isFirstDayOfMonth = d.getDate() === 1;
    // const isCurrentMonthFirst = d.getFullYear() === today.getFullYear() && d.getMonth() === today.getMonth();
    const isFutureMonth = d > today && d.getDate() === 1;
    return isFirstDayOfMonth && isFutureMonth;
  }

  getHolderAgeFilter(d: Date | null): boolean {
    const today = new Date();
    const birthDate = d;
    const yearDiff = today.getFullYear() - birthDate?.getFullYear();
    const monthDiff = today.getMonth() - birthDate?.getMonth();
    const dayDiff = today.getDate() - birthDate?.getDate();

    return !(
      yearDiff < 18 ||
      (yearDiff === 18 && monthDiff < 0) ||
      (yearDiff === 18 && monthDiff === 0 && dayDiff < 0) ||
      yearDiff > 72 ||
      (yearDiff === 72 && monthDiff > 0) ||
      (yearDiff === 72 && monthDiff === 0 && dayDiff > 0)
    );
  }

  getHolderAgeStartDate(): Date {
    const today = new Date();
    const startYear = today.getFullYear() - 18;
    const startMonth = today.getMonth();
    const startDay = today.getDate();
    return new Date(startYear, startMonth, startDay);
  }

  goToPersonalizedPlans(): void {
    if (this.hasUnsavedChanges() && this.quoteForm.get('email').value) {
      this.saveData(false);
    } else {
      if (!this.quotingData) {
        this.quotingData = this.appService.getMappedQuoting(
          this.getCurrentQuoteData(this.quoteForm.getRawValue()),
          false
        );
      }
    }
    this.goToPackagesStepEvent.emit(this.getCurrentQuoteData(this.quoteForm.getRawValue()));
  }

  saveQuoteAndSendLink() {
    let submittedData: ApplicationView;
    if (this.appService.getUnsavedData() && this.isAgentMode) {
      submittedData = this.appService.combineValidUnsavedData(this.getCurrentQuoteData(this.quoteForm.getRawValue()));
    } else {
      submittedData = this.getCurrentQuoteData(this.quoteForm.getRawValue());
    }
    if (this.customerService.isListBillModeActive()) {
      submittedData.listBillId = this.customerService.getListBillId();
    }
    this.saveQuoteWithSendLinkEvent.emit({ appData: submittedData, type: 'email' });
    this.isEmailEditMode = false;
  }

  saveData(navigateToNextStep: boolean, unCompleteSteps?: boolean) {
    let currentQuoteData: ApplicationView;
    if (this.serverData?.signatures?.length) {
      currentQuoteData = this.getCurrentQuoteData(this.quoteForm.getRawValue(), true);
    } else {
      currentQuoteData = this.getCurrentQuoteData(this.quoteForm.getRawValue());
    }

    const quoteDataForSave = unCompleteSteps
      ? { ...currentQuoteData, packages: [], selectedPackageId: null }
      : currentQuoteData;
    const submittedData = {
      formData: quoteDataForSave,
      navigateToNextStep: navigateToNextStep,
    };
    this.saveQuoteEvent.emit(submittedData);
  }

  getCurrentQuoteData(value: MappedQuotingView, isSignAgreementReset?: boolean): ApplicationView {
    const res: ApplicationView = this.getDataEntryForSubmit(value, isSignAgreementReset);

    if (value.customers?.length) {
      this.addDependentForSubmit(value, res);
    }

    if (this.quotingData) {
      res.id = this.quotingData.id;
    }

    return res;
  }

  getDataEntryForSubmit(value: MappedQuoting, isSignAgreementReset?: boolean): ApplicationView {
    return {
      agentId: this.quotingData ? this.quotingData.agentId : this.customerService.getAgentId(),
      submissionDate: this.serverData ? this.serverData.submissionDate : null,
      signatures: isSignAgreementReset ? [] : this.serverData ? this.serverData.signatures : [],
      timeZoneOffsetMinutes: getTimeZoneOffsetMinutes(),
      updateSignature: isSignAgreementReset ? true : false,
      startDate: this.datePipe.transform(value.startDate, 'yyyy-MM-dd'),
      contactInfo: {
        email: value.email,
        zipCode: value.zipCode.trim(),
        city: this.serverData ? this.serverData.contactInfo.city : null,
        phone: this.serverData ? this.serverData.contactInfo.phone : null,
        streetAddress: this.serverData ? this.serverData.contactInfo.streetAddress : null,
        mailingStreetAddress: this.serverData ? this.serverData.contactInfo.mailingStreetAddress : null,
        mailingCity: this.serverData ? this.serverData.contactInfo.mailingCity : null,
        mailingZipCode: this.serverData ? this.serverData.contactInfo.mailingZipCode : null,
      },
      customers: [
        {
          customerType: 1,
          dateOfBirth: this.datePipe.transform(value.dateOfBirth, 'yyyy-MM-dd'),
          genderType: value.genderType,
          sequence: 0,
          firstName: this.serverData
            ? this.serverData.customers.find(customer => customer.customerType === 1)?.firstName
            : null,
          lastName: this.serverData
            ? this.serverData.customers.find(customer => customer.customerType === 1)?.lastName
            : null,
        },
      ],
      step: this.serverData?.step ? this.serverData?.step : 0,
      packages: this.serverData ? this.serverData.packages : [],
      selectedPackageId: this.serverData ? this.serverData.selectedPackageId : null,
    };
  }

  addDependentForSubmit(value: MappedQuoting, res: ApplicationView): void {
    value.customers.forEach((customer, index) => {
      const customerFromServerData = this.serverData?.customers.find(customer => customer.sequence === index + 1);
      const newCustomer = {
        customerType: customer.customerType,
        dateOfBirth: this.datePipe.transform(customer.dateOfBirth, 'yyyy-MM-dd'),
        genderType: +customer.genderType,
        sequence: index + 1,
        firstName: customerFromServerData ? customerFromServerData.firstName : null,
        lastName: customerFromServerData ? customerFromServerData.lastName : null,
      };
      res.customers.push(newCustomer);
    });
  }

  onEmailChangeClick() {
    this.isEmailEditMode = true;
  }

  onSaveLinkClick(e) {
    e.stopPropagation();
    if (this.quoteForm.valid) {
      this.showSaveQuoteDialog();
    } else {
      this.stepperService.openStep(0);
      this.quoteForm.markAsTouched();
      this.quoteForm.updateValueAndValidity();
    }
  }

  onSaveQuoteModalConfirmClick() {
    if (this.saveQuoteEmailControl.valid) {
      this.quoteForm.get('email').setValue(this.saveQuoteEmailControl.value);
      this.saveQuoteAndSendLink();
      this.saveQuoteDialogRef.close();
    } else {
      this.saveQuoteEmailControl.updateValueAndValidity();
      this.saveQuoteEmailControl.markAsTouched();
    }
  }

  private showSaveQuoteDialog() {
    this.saveQuoteEmailControl = new FormControl('');
    this.saveQuoteEmailControl.addValidators([Validators.required, Validators.pattern(regexPatterns.email)]);
    const saveQuoteModalData: ModalDialogData = {
      id: 'confirmationModalData',
      title: 'Save Quote',
      cancelActionText: 'Cancel',
      showCancelBtn: true,
      showConfirmBtn: true,
      confirmActionText: 'Save',
      hasContent: true,
      onConfirmClick: this.onSaveQuoteModalConfirmClick.bind(this),
    };
    this.saveQuoteDialogRef = this.dialog.open(this.saveQuoteDialog, {
      data: saveQuoteModalData,
      width: '400px',
      autoFocus: false,
      disableClose: false,
    });

    this.saveQuoteDialogRef.afterClosed().subscribe(() => {
      this.saveQuoteEmailControl = null;
    });
  }

  private showChangeDataDialog(prevFormValue: MappedQuoting, changedFormValue: MappedQuoting) {
    const changeDataModalData: ModalDialogData = {
      id: 'changeDataDialog',
      title: 'Do you want to Change your Quote?',
      cancelActionText: 'Cancel',
      showCancelBtn: true,
      showConfirmBtn: true,
      confirmActionText: 'Apply new Input',
      hasContent: true,
    };

    this.changeDataDialogRef = this.dialog.open(this.changeDataDialog, {
      data: changeDataModalData,
      width: '450px',
      autoFocus: false,
      disableClose: true,
    });

    this.changeDataDialogRef.afterClosed().subscribe(confirmRes => {
      if (confirmRes) {
        this.checkCustomersChange(prevFormValue, changedFormValue, true);
        this.quoteForm.patchValue(changedFormValue, { emitEvent: false });
        if (this.quoteForm.valid && this.quoteForm.get('email').value) {
          this.saveData(false, true);
        }
        this.unCompleteAllStepsEvent.emit();
        this.quoteForm.updateValueAndValidity();
      } else {
        this.checkCustomersChange(prevFormValue, changedFormValue, false);
        this.quoteForm.patchValue(prevFormValue, { emitEvent: false });
      }
      this.currentFormValue = this.quoteForm.value;
    });
  }

  private checkCustomersChange(prevFormValue: MappedQuoting, changedFormValue: MappedQuoting, applyChanges: boolean) {
    if (prevFormValue.customers.length !== changedFormValue.customers.length) {
      applyChanges ? this.fillCustomersFormArray(changedFormValue) : this.fillCustomersFormArray(prevFormValue);
    }
  }

  private hasUnsavedChanges(): boolean {
    const currentData = this.getCurrentQuoteData(this.quoteForm.getRawValue());
    return !isEqual(this.serverData, currentData);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
