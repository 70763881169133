import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IStepState } from '../models/common.interface';

@Injectable({
  providedIn: 'root',
})
export class StepperService {
  private stepperState: IStepState[] = [
    { stepName: 'Quote', isEnabled: false, isCompleted: false, isOpened: false },
    { stepName: 'Select Product', isEnabled: false, isCompleted: false, isOpened: false },
    { stepName: 'Review & Modify Benefits', isEnabled: false, isCompleted: false, isOpened: false },
    { stepName: 'Enrollment', isEnabled: false, isCompleted: false, isOpened: false },
    { stepName: 'Purchase', isEnabled: false, isCompleted: false, isOpened: false },
  ];

  stepperStateSubject$ = new BehaviorSubject(this.stepperState);
  constructor() {}

  setInitialStep(index: number) {
    for (let i = 0; i < this.stepperState.length; i++) {
      this.stepperState[i].isEnabled = true;
      if (index > 0) {
        this.stepperState[i].isCompleted = true;
        if (i !== this.stepperState.length - 1) {
          this.stepperState[i + 1].isEnabled = true;
        }
      }
      if (i === index) {
        this.stepperState[i].isOpened = true;
        break;
      }
    }
  }

  completeStep(i: number) {
    this.stepperState[i].isCompleted = true;
    if (i !== 4) {
      this.stepperState[i].isOpened = false;
      this.stepperState[i + 1].isEnabled = true;
      this.stepperState[i + 1].isOpened = true;
    }
    this.stepperStateSubject$.next(this.stepperState);
    setTimeout(() => {
      const stepperContainer = document.getElementById('stepperContainer');
      stepperContainer.scrollIntoView({ behavior: 'smooth' });
    }, 250);
  }

  unCompleteStep(i: number) {
    this.stepperState[i].isCompleted = false;
    this.stepperState[i].isOpened = false;
    this.stepperState[i].isEnabled = false;
    this.stepperStateSubject$.next(this.stepperState);
  }

  unCompleteAllSteps() {
    this.stepperState.forEach((step, i) => {
      if (i !== 0) {
        step.isCompleted = false;
        step.isEnabled = false;
      }
      this.stepperStateSubject$.next(this.stepperState);
    });
  }

  openStep(i: number) {
    this.stepperState[i].isOpened = true;
    this.stepperStateSubject$.next(this.stepperState);
  }
}
