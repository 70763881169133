import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import {
  ApplicationView,
  Customer,
  IPackage,
  ISignature,
  MappedQuotingView,
} from '../../../../shared/models/application.interface';
import { CommonModule, DatePipe } from '@angular/common';
import { PlanStartDateSectionComponent } from '../../../../shared/components/plan-start-date-section/plan-start-date-section.component';
import { CustomerItemComponent } from '../../../../shared/components/customer-item/customer-item.component';
import { CustomerTypeEnum } from '../../../../shared/enums/customerType.enum';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { ISendLinkData, IStepState } from '../../../../shared/models/common.interface';
import { StepperService } from '../../../../shared/services/stepper.service';
import { Subject, take, takeUntil } from 'rxjs';
import { startDateRangeValidation } from '../../../../shared/utils/custom-validators';
import { getStateByZipCode } from '../../../../shared/utils/state-by-zipcode';
import { CustomerService } from '../../../../shared/services/customer.service';
import { regexPatterns } from '../../../../shared/utils/regex';
import { cloneDeep, isEqual } from 'lodash-es';
import { ModalDialogData } from '../../../../shared/models/modal-dialog.interface';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalDialogComponent } from '../../../../shared/components/modal-dialog/modal-dialog.component';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { AppRepository } from '../../../../shared/repositories/app.repository';
import { zipCodeValidation } from '../../../../shared/utils/zip-code-validation';
import { convertStringToLocalDate, getTimeZoneOffsetMinutes } from '../../../../shared/utils/date-format';
import { SafeHtmlPipe } from '../../../../shared/pipes/safe-html.pipe';
import { SignatureTypeEnum } from '../../../../shared/enums/signatureType.enum';
import { AppService } from '../../../../shared/services/app.service';
import { DownloadService } from '../../../../shared/services/download.service';
import { ControlTrimFormatDirective } from '../../../../shared/directives/control-trim-format.directive';
import { InsuranceDocumentTypeEnum } from '../../../../shared/enums/insuranceDocumentType.enum';

@Component({
  selector: 'app-enrollment-step',
  standalone: true,
  imports: [
    PlanStartDateSectionComponent,
    CustomerItemComponent,
    ModalDialogComponent,
    CommonModule,
    ReactiveFormsModule,
    MatIcon,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatRadioGroup,
    MatRadioButton,
    SafeHtmlPipe,
    ControlTrimFormatDirective,
  ],
  templateUrl: './enrollment-step.component.html',
  styleUrl: './enrollment-step.component.scss',
  providers: [DatePipe],
})
export class EnrollmentStepComponent implements OnInit {
  @ViewChild('acceptanceDialog') acceptanceDialog: TemplateRef<any>;
  @ViewChild('changeDataDialog') changeDataDialog: TemplateRef<any>;
  @ViewChild('shareAppBySmsDialog') shareAppBySmsDialog: TemplateRef<any>;
  @Input() set quote(value: ApplicationView) {
    if (value) {
      let updatedValue = value;

      if (this.appService.getUnsavedData()) {
        updatedValue = this.appService.combineUnsavedData(value);
      }

      this.buildForms();
      this.serverData = updatedValue;
      this.quotingData = this.appService.getMappedQuoting(updatedValue, true);

      if (this.invalidEnrolmentFormValue) {
        this.quotingData = this.appService.getMappedQuoting(this.invalidEnrolmentFormValue, true);
      }

      this.fillForms(this.quotingData);

      if (this.invalidEnrolmentFormValue) {
        this.invalidEnrolmentFormValue = null;
        this.enrollmentForm.markAllAsTouched();
      }

      if (this.isAgentMode && value.signatures.length) {
        this.signAgreementForm.get('agentSignatureType').disable({ emitEvent: false });
      }

      if (value.signatures.length) {
        this.setSignatures();
      }

      if (value.isPaymentSubmitted) {
        this.enrollmentForm.disable({ emitEvent: false });
        this.signAgreementForm.disable({ emitEvent: false });
      }
    }
  }
  @Input() set selectedPackage(value: IPackage) {
    if (value) {
      this.selectedPackageData = value;
    }
  }
  @Output() signAgreementEvent: EventEmitter<ApplicationView> = new EventEmitter<ApplicationView>();
  @Output() saveQuoteWithSendLinkEvent: EventEmitter<ISendLinkData> = new EventEmitter<ISendLinkData>();
  @Input() isQuoteSaved: boolean;
  changeDataDialogRef: MatDialogRef<ModalDialogComponent>;
  acceptanceDialogRef: MatDialogRef<ModalDialogComponent>;
  shareAppBySmsDialogRef: MatDialogRef<ModalDialogComponent>;
  quotingData: MappedQuotingView;
  selectedPackageData: IPackage;
  serverData: ApplicationView;
  enrollmentForm: FormGroup;
  signAgreementForm: FormGroup;
  stepState: IStepState;
  isAgentMode: boolean;
  acceptanceRules: string = '';
  signatureType = SignatureTypeEnum;
  insuranceDocumentType = InsuranceDocumentTypeEnum;
  insuranceSignature: ISignature;
  communicatingAmericaSignature: ISignature;
  invalidEnrolmentFormValue: ApplicationView = null;
  destroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private customerService: CustomerService,
    private datePipe: DatePipe,
    private stepperService: StepperService,
    private dialog: MatDialog,
    private appRepository: AppRepository,
    private appService: AppService,
    private downloadService: DownloadService
  ) {}

  ngOnInit(): void {
    this.isAgentMode = this.customerService.isAgentModeActive();
    this.setStepState();
    this.buildForms();
  }

  updateUnsavedData(): void {
    if (!this.isQuoteSaved) {
      const submittedData = this.getCurrentFormData(this.enrollmentForm.getRawValue());
      this.appService.setUnsavedData(submittedData);
    } else {
      this.appService.setUnsavedData(null);
    }
  }

  resetUnsavedData(): void {
    if (this.isQuoteSaved) {
      this.appService.setUnsavedData(null);
    }
  }

  setStepState() {
    this.stepperService.stepperStateSubject$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.stepState = res[3];
    });
  }

  setSignatures(): void {
    this.insuranceSignature = this.quotingData.signatures.find(
      signature => signature.documentType === InsuranceDocumentTypeEnum.INSURANCE
    );
    this.communicatingAmericaSignature = this.quotingData.signatures.find(
      signature => signature.documentType === InsuranceDocumentTypeEnum.COMMUNICATING_FOR_AMERICA
    );
  }

  buildForms(): void {
    this.enrollmentForm = this.formBuilder.group({
      startDate: new FormControl(null, [Validators.required, startDateRangeValidation()]),
      email: new FormControl('', [Validators.required, Validators.pattern(regexPatterns.email)]),
      phone: new FormControl('', [Validators.required, Validators.pattern(regexPatterns.phone)]),
      streetAddress: new FormControl('', Validators.required),
      zipCode: new FormControl({ value: '', disabled: true }, Validators.required),
      city: new FormControl('', Validators.required),
      state: new FormControl({ value: '', disabled: true }, Validators.required),
      isAddressTheSame: new FormControl(true),
      mailingStreetAddress: new FormControl(''),
      mailingZipCode: new FormControl(''),
      mailingCity: new FormControl(''),
      mailingState: new FormControl({ value: '', disabled: true }),
      customers: new FormArray([]),
    });

    this.signAgreementForm = this.formBuilder.group({
      insuranceAcceptance: new FormControl(false, this.acceptanceValidation()),
      insuranceSignature: new FormControl('', [Validators.required, this.signatureValidation()]),
      communicatingAmericaAcceptance: new FormControl(false, this.acceptanceValidation()),
      communicatingAmericaSignature: new FormControl('', [Validators.required, this.signatureValidation()]),
    });

    if (this.isAgentMode) {
      const agentSignatureTypeControl = new FormControl(null, Validators.required);
      this.signAgreementForm.addControl('agentSignatureType', agentSignatureTypeControl, { emitEvent: false });
    }
  }

  acceptanceValidation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return !control.value ? { acceptanceError: true } : null;
    };
  }

  signatureValidation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      const value = control.value.toUpperCase();
      const policyHolderControl = this.customersFormArray?.controls.find(
        control => control.get('customerType').value === CustomerTypeEnum.FORM_SUBMITTER
      );
      const firstNamePolicyHolder = policyHolderControl?.get('firstName').value?.trim().toUpperCase();
      const lastNamePolicyHolder = policyHolderControl?.get('lastName').value?.trim().toUpperCase();
      const isValid = value.includes(firstNamePolicyHolder) || value.includes(lastNamePolicyHolder);

      return !isValid ? { signatureError: true } : null;
    };
  }

  fillForms(value: MappedQuotingView): void {
    this.enrollmentForm.patchValue(value);
    this.enrollmentForm.get('startDate').setValue(convertStringToLocalDate(value.startDate), { emitEvent: false });

    if (value.customers) {
      this.fillCustomersFormArray(value.customers);
    }

    if (this.isAgentMode && value.signatures) {
      const agentSignatureType = value.signatures[0]?.signatureType;
      this.signAgreementForm.get('agentSignatureType').setValue(agentSignatureType);
    }

    this.setStates();
    this.checkAddressSection(value);
    this.initSignatureValidationListener();

    if (this.serverData?.signatures.length) {
      this.initConfirmedFormChangeListener();
    }
  }

  initSignatureValidationListener(): void {
    const policyHolderControl = this.customersFormArray?.controls.find(
      item => item.get('customerType').value === CustomerTypeEnum.FORM_SUBMITTER
    );

    policyHolderControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.signAgreementForm.get('insuranceSignature').updateValueAndValidity({ emitEvent: false });
      this.signAgreementForm.get('communicatingAmericaSignature').updateValueAndValidity({ emitEvent: false });
    });
  }

  onHolderNameChange() {
    const policyHolderControl = this.customersFormArray?.controls.find(
      item => item.get('customerType').value === CustomerTypeEnum.FORM_SUBMITTER
    );
    const nameValue = `${policyHolderControl.value.firstName} ${policyHolderControl.value.lastName}`;
    this.signAgreementForm.get('insuranceSignature').setValue(nameValue, { emitEvent: false });
    this.signAgreementForm.get('communicatingAmericaSignature').setValue(nameValue, { emitEvent: false });
  }

  checkAddressSection(value: MappedQuotingView): void {
    if (value.mailingStreetAddress || value.mailingCity || value.mailingZipCode || this.invalidEnrolmentFormValue) {
      const isStreetAddressTheSame = value.streetAddress === value.mailingStreetAddress;
      const isCityTheSame = value.city === value.mailingCity;
      const isZipCodeTheSame = value.zipCode === value.mailingZipCode;
      const isAddressTheSame = isStreetAddressTheSame && isCityTheSame && isZipCodeTheSame;

      if (isAddressTheSame) {
        this.enrollmentForm.get('isAddressTheSame').setValue(true);
        this.removeMailingAddressValidationRules();
      } else {
        this.enrollmentForm.get('isAddressTheSame').setValue(false);
        this.setMailingAddressValidationRules();
      }
    }
  }

  setStates(): void {
    const zipCode = getStateByZipCode(this.enrollmentForm.get('zipCode').value);
    this.enrollmentForm.get('state').setValue(zipCode);

    const mailingZipCode = getStateByZipCode(this.enrollmentForm.get('mailingZipCode').value);
    this.enrollmentForm.get('mailingState').setValue(mailingZipCode);
  }

  get currentMailingState(): string {
    if (this.enrollmentForm.get('mailingZipCode').invalid) {
      return '';
    } else {
      return getStateByZipCode(this.enrollmentForm.get('mailingZipCode').value);
    }
  }

  fillCustomersFormArray(value: Customer[]): void {
    value
      .sort((a, b) => a.sequence - b.sequence)
      .forEach(customer => {
        const newCustomer = this.getNewCustomerControl(null);
        newCustomer.patchValue(customer);
        newCustomer.get('dateOfBirth').setValue(convertStringToLocalDate(customer.dateOfBirth), { emitEvent: false });
        this.customersFormArray.push(newCustomer);
      });
  }

  getNewCustomerControl(customerType: number) {
    return this.formBuilder.group({
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      dateOfBirth: new FormControl(null, Validators.required),
      genderType: new FormControl(null, Validators.required),
      customerType: new FormControl(customerType, Validators.required),
    });
  }

  get customersFormArray(): FormArray {
    return this.enrollmentForm.get('customers') as FormArray;
  }

  get childrenFormArray(): any {
    return this.customersFormArray?.controls.filter(item => item.get('customerType').value === CustomerTypeEnum.CHILD);
  }

  getChildIndex(childCustomer: AbstractControl): number {
    return this.childrenFormArray?.indexOf(childCustomer);
  }

  onAcceptanceChange(checkBox: MatCheckboxChange, documentType: number): void {
    if (checkBox.checked === true) {
      if (documentType === InsuranceDocumentTypeEnum.INSURANCE) {
        this.signAgreementForm.get('insuranceAcceptance').setValue(!checkBox.checked);
      } else {
        this.signAgreementForm.get('communicatingAmericaAcceptance').setValue(!checkBox.checked);
      }
      this.openAcceptanceDialog(documentType);
    }
  }

  openAcceptanceDialog(documentType: number): void {
    const modalDialogData: ModalDialogData = this.getModalDialogData();
    const selectedBenefitIds = this.appService.generateSelectedBenefitIds(this.selectedPackageData);
    const state = this.enrollmentForm.get('state').value;
    this.appRepository
      .getAcceptanceRules(selectedBenefitIds, documentType, state)
      .pipe(take(1))
      .subscribe((res: string) => {
        this.acceptanceRules = res;
        this.activateAcceptanceDialogRef(modalDialogData, documentType);
      });
  }

  onSignAgreementClick(): void {
    const submittedData = this.getCurrentFormData(this.enrollmentForm.getRawValue());
    this.signAgreementEvent.emit(submittedData);
  }

  goToPurchaseStep(): void {
    this.stepperService.completeStep(3);
  }

  updateStartDateWithResetSign(): void {
    const submittedData = this.getCurrentFormData(this.enrollmentForm.getRawValue(), true);
    this.signAgreementEvent.emit(submittedData);
  }

  getCurrentFormData(value: MappedQuotingView, isSignAgreementReset?: boolean): ApplicationView {
    const res: ApplicationView = this.getDataEntryForSubmit(value);

    if (value.customers?.length) {
      this.addDependentForSubmit(value, res);
    }

    if (this.quotingData) {
      res.id = this.quotingData.id;
    }

    if (!isSignAgreementReset && this.signAgreementForm.valid) {
      this.addSignatures(res);
    }

    return res;
  }

  getDataEntryForSubmit(value: MappedQuotingView): ApplicationView {
    const isAddressTheSame = this.enrollmentForm.get('isAddressTheSame').value;
    return {
      agentId: this.quotingData ? this.quotingData.agentId : this.customerService.getAgentId(),
      updateSignature: true,
      timeZoneOffsetMinutes: getTimeZoneOffsetMinutes(),
      signatures: [],
      submissionDate: this.serverData ? this.serverData.submissionDate : null,
      startDate: this.datePipe.transform(value.startDate, 'yyyy-MM-dd'),
      contactInfo: {
        email: value.email,
        zipCode: value.zipCode,
        city: value.city,
        streetAddress: value.streetAddress,
        mailingStreetAddress: !isAddressTheSame ? value.mailingStreetAddress : value.streetAddress,
        mailingCity: !isAddressTheSame ? value.mailingCity : value.city,
        mailingZipCode: !isAddressTheSame ? value.mailingZipCode : value.zipCode,
        phone: value.phone,
      },
      customers: [],
      step: this.serverData?.step ? this.serverData?.step : 3,
      packages: this.serverData?.packages ? this.serverData?.packages : [],
      selectedPackageId: this.serverData?.selectedPackageId ? this.serverData?.selectedPackageId : null,
    };
  }

  addDependentForSubmit(value: MappedQuotingView, res: ApplicationView): void {
    value.customers.forEach((customer, index) => {
      const newCustomer = {
        lastName: customer.lastName,
        firstName: customer.firstName,
        customerType: customer.customerType,
        dateOfBirth: this.datePipe.transform(customer.dateOfBirth, 'yyyy-MM-dd'),
        genderType: +customer.genderType,
        sequence: index + 1,
      };
      res.customers.push(newCustomer);
    });
  }

  addSignatures(res: ApplicationView): void {
    const signatureType = !this.isAgentMode
      ? SignatureTypeEnum.BY_APPLICANT
      : this.signAgreementForm.get('agentSignatureType').value;
    const companionLifeSignature = {
      signature: this.signAgreementForm.get('insuranceSignature').value,
      documentType: InsuranceDocumentTypeEnum.INSURANCE,
      signatureType: signatureType,
    };
    const communicatingAmericaSignature = {
      signature: this.signAgreementForm.get('communicatingAmericaSignature').value,
      documentType: InsuranceDocumentTypeEnum.COMMUNICATING_FOR_AMERICA,
      signatureType: signatureType,
    };

    res.signatures.push(companionLifeSignature);
    res.signatures.push(communicatingAmericaSignature);
  }

  shareAppToClientClick(type: 'email' | 'sms') {
    const submittedData = this.getCurrentFormData(this.enrollmentForm.getRawValue());
    if (!this.serverData?.signatures.length) {
      submittedData.signatures = [];
      this.saveQuoteWithSendLinkEvent.emit({ appData: submittedData, type: type });
    } else {
      if (type === 'email') {
        this.appRepository.sendApplicationQuoteLinkByMail(this.customerService.getAppId()).subscribe();
      } else if (type === 'sms') {
        this.saveQuoteWithSendLinkEvent.emit({
          appData: {
            ...submittedData,
            signatures: this.serverData?.signatures.length ? this.serverData?.signatures : [],
          },
          type: type,
        });
      }
    }
  }

  shareAppBySmsClick() {
    const shareAppModalData: ModalDialogData = {
      id: 'shareAppBySmsDialog',
      title: "Client's permission confirmation",
      cancelActionText: 'Cancel',
      showCancelBtn: true,
      showConfirmBtn: true,
      confirmActionText: 'Confirm',
      hasContent: true,
    };

    this.shareAppBySmsDialogRef = this.dialog.open(this.shareAppBySmsDialog, {
      data: shareAppModalData,
      width: '550px',
      autoFocus: false,
      disableClose: false,
    });

    this.shareAppBySmsDialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.shareAppToClientClick('sms');
      }
    });
  }

  onMailingAddressChange(checkboxChange: MatCheckboxChange): void {
    if (checkboxChange.checked) {
      this.removeMailingAddressValidationRules();
    } else {
      this.resetMailingAddress();
      this.setMailingAddressValidationRules();
    }
  }

  onPreviewCompleteAgreementClick(documentType: number): void {
    const submittedData = this.getCurrentFormData(this.enrollmentForm.getRawValue());
    const selectedBenefitIds: string[] = this.appService.generateSelectedBenefitIds(this.selectedPackageData);
    const params = this.getPreviewAgreementParams(submittedData, selectedBenefitIds);

    this.appRepository
      .getPreviewCompleteAgreement(params, documentType)
      .pipe(take(1))
      .subscribe(res => {
        this.downloadService.openFile(res);
      });
  }

  private getModalDialogData(): ModalDialogData {
    return {
      adaptivePosition: true,
      title: 'Acceptance and Acknowledgement',
      dialogContainerClass: 'acceptance-modal-dialog',
      hideCloseBtn: false,
      hasContent: true,
      hideActions: false,
      showConfirmBtn: true,
      showCancelBtn: true,
      confirmActionText: `I confirm I've Read and Accept The Conditions`,
      cancelActionText: 'Cancel',
    };
  }

  private activateAcceptanceDialogRef(modalDialogData: ModalDialogData, documentType: number): void {
    this.acceptanceDialogRef = this.dialog.open(this.acceptanceDialog, {
      data: modalDialogData,
      width: '80vw',
      maxWidth: '1400px',
      autoFocus: false,
      disableClose: true,
    });

    this.acceptanceDialogRef.afterClosed().subscribe(confirm => {
      if (documentType === InsuranceDocumentTypeEnum.INSURANCE) {
        this.signAgreementForm.get('insuranceAcceptance').setValue(!!confirm);
      } else {
        this.signAgreementForm.get('communicatingAmericaAcceptance').setValue(!!confirm);
      }
    });
  }

  private initConfirmedFormChangeListener(): void {
    const currentFormValue = this.enrollmentForm.value;
    const prevFormValue = cloneDeep(currentFormValue);

    this.enrollmentForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(changedFormValue => {
      const hasDif = !isEqual(prevFormValue, changedFormValue);
      const changedFieldName = this.appService.getChangedFieldName(prevFormValue, changedFormValue);

      if (hasDif && !changedFieldName.includes('startDate')) {
        this.showChangeDataDialog(prevFormValue);
      }
    });
  }

  private showChangeDataDialog(prevFormValue: MappedQuotingView) {
    const changeDataModalData: ModalDialogData = {
      id: 'changeDataDialog',
      title: 'Do you want to Change your Quote?',
      cancelActionText: 'Cancel',
      showCancelBtn: true,
      showConfirmBtn: true,
      confirmActionText: 'Apply new Input',
      hasContent: true,
    };

    this.changeDataDialogRef = this.dialog.open(this.changeDataDialog, {
      data: changeDataModalData,
      width: '450px',
      autoFocus: false,
      disableClose: true,
    });

    this.changeDataDialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        const submittedData = this.getCurrentFormData(this.enrollmentForm.getRawValue(), true);
        if (this.enrollmentForm.valid) {
          this.signAgreementEvent.emit(submittedData);
        } else {
          this.invalidEnrolmentFormValue = submittedData;
          const reseedData = this.appService.setEmptySignature(this.serverData);
          this.signAgreementEvent.emit(reseedData);
        }
      } else {
        this.enrollmentForm.patchValue(prevFormValue, { emitEvent: false });
      }
    });
  }

  private setMailingAddressValidationRules(): void {
    this.enrollmentForm.get('mailingStreetAddress').setValidators(Validators.required);
    this.enrollmentForm.get('mailingCity').setValidators(Validators.required);
    this.enrollmentForm.get('mailingZipCode').setValidators([Validators.required, zipCodeValidation()]);
    this.enrollmentForm.get('mailingState').setValidators(Validators.required);
    this.updateMailingAddressValidation();
  }

  private resetMailingAddress(): void {
    this.enrollmentForm.get('mailingStreetAddress').markAsUntouched();
    this.enrollmentForm.get('mailingCity').markAsUntouched();
    this.enrollmentForm.get('mailingZipCode').markAsUntouched();
    this.enrollmentForm.get('mailingState').markAsUntouched();

    this.enrollmentForm.get('mailingStreetAddress').setValue('', { emitEvent: false });
    this.enrollmentForm.get('mailingCity').setValue('', { emitEvent: false });
    this.enrollmentForm.get('mailingZipCode').setValue('', { emitEvent: false });
    this.enrollmentForm.get('mailingState').setValue('', { emitEvent: false });
  }

  private removeMailingAddressValidationRules(): void {
    this.enrollmentForm.get('mailingStreetAddress').removeValidators(Validators.required);
    this.enrollmentForm.get('mailingCity').removeValidators(Validators.required);
    this.enrollmentForm.get('mailingZipCode').removeValidators([Validators.required, zipCodeValidation()]);
    this.enrollmentForm.get('mailingState').removeValidators(Validators.required);
    this.updateMailingAddressValidation();
  }

  private updateMailingAddressValidation(): void {
    this.enrollmentForm.get('mailingStreetAddress').updateValueAndValidity({ emitEvent: false });
    this.enrollmentForm.get('mailingCity').updateValueAndValidity({ emitEvent: false });
    this.enrollmentForm.get('mailingZipCode').updateValueAndValidity({ emitEvent: false });
    this.enrollmentForm.get('mailingState').updateValueAndValidity({ emitEvent: false });
  }

  private getPreviewAgreementParams(submittedData: ApplicationView, selectedBenefitIds: string[]) {
    return {
      applicationId: this.customerService.getAppId(),
      packageId: submittedData.selectedPackageId,
      agentId: submittedData.agentId,
      startDate: submittedData.startDate,
      email: submittedData.contactInfo.email,
      phone: submittedData.contactInfo.phone,
      address: {
        type: 1,
        zipCode: submittedData.contactInfo.zipCode,
        city: submittedData.contactInfo.city,
        streetAddress: submittedData.contactInfo.streetAddress,
      },
      selectedBenefitLevelIds: selectedBenefitIds,
      customers: submittedData.customers,
    };
  }
}
