import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { IStepState } from '../../../../shared/models/common.interface';
import { Subject, takeUntil } from 'rxjs';
import { StepperService } from '../../../../shared/services/stepper.service';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { ApplicationView, IPackage } from '../../../../shared/models/application.interface';
import { find } from 'lodash-es';
import { CurrencyPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { PriceFormatTransformPipe } from '../../../../shared/pipes/price-format.pipe';
import { DomPortalOutlet, PortalOutlet, TemplatePortal } from '@angular/cdk/portal';
import { PrintPageHeaderComponent } from '../../../../shared/components/print-page-header/print-page-header.component';
import { PrintService } from '../../../../shared/services/print.service';
import { getStateByZipCode } from '../../../../shared/utils/state-by-zipcode';
import { CustomerService } from '../../../../shared/services/customer.service';

@Component({
  selector: 'app-insurance-plan-step',
  standalone: true,
  imports: [
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatIcon,
    MatButton,
    CurrencyPipe,
    PriceFormatTransformPipe,
    NgTemplateOutlet,
    PrintPageHeaderComponent,
    NgClass,
  ],
  templateUrl: './insurance-plan-step.component.html',
  styleUrl: './insurance-plan-step.component.scss',
})
export class InsurancePlanStepComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('printContent') printContentRef: TemplateRef<any>;
  @ViewChild('printIframe') printIframeRef: ElementRef;
  @Input() set packagesList(value: IPackage[]) {
    if (value) {
      this.packagesListData = value;
      this.packagesListData.forEach(packageItem => {
        this.setPricesForDisplay(packageItem);
      });
    }
  }
  @Input() set selectedPackage(value: IPackage) {
    this.selectedPackageData = value;
    if (value) {
      this.setPricesForDisplay(value);
    }
  }
  @Input() set quote(value: ApplicationView) {
    if (value) {
      this.quoteData = value;
      this.currentState = getStateByZipCode(+value.contactInfo.zipCode);
    }
  }
  @Output() selectPackageEvent: EventEmitter<IPackage> = new EventEmitter<IPackage>();
  stepState: IStepState;
  currentState: string;
  packagesListData: IPackage[];
  selectedPackageData: IPackage;
  quoteData: ApplicationView;
  isListBillMode: boolean;
  destroy$ = new Subject<void>();
  private printIframe;
  private portalHost: PortalOutlet;
  constructor(
    private stepperService: StepperService,
    private viewContainerRef: ViewContainerRef,
    private printService: PrintService,
    private customerService: CustomerService,
  ) {}

  ngOnInit() {
    this.isListBillMode = this.customerService.isListBillModeActive();
    this.setStepState();
  }

  ngAfterViewInit() {
    this.preparePrintIframe();
  }

  setStepState() {
    this.stepperService.stepperStateSubject$.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.stepState = res[1];
    });
  }

  onSelectPackageClick(packageItem: IPackage) {
    this.selectPackageEvent.emit(packageItem);
  }

  onPrintListClick(e) {
    e.stopPropagation();
    this.portalHost = new DomPortalOutlet(this.printIframe.contentDocument.body);
    const portal = new TemplatePortal(this.printContentRef, this.viewContainerRef, {
      packagesListData: this.packagesListData,
    });
    this.portalHost.attach(portal);
    this.printIframe.contentWindow.onafterprint = () => {
      this.printIframe.contentDocument.body.innerHTML = '';
    };
    this.printService.print(this.printIframe);
  }

  private preparePrintIframe() {
    this.printIframe = this.printIframeRef.nativeElement;
    this.printService.attachStyles(this.printIframe.contentWindow);
  }

  private setPricesForDisplay(packageItem: IPackage) {
    packageItem.price = 0;
    packageItem.pricePerPeriod = 0;
    packageItem.companies.forEach(company => {
      company.products.forEach(product => {
        packageItem.price += product.price;
        if (this.isListBillMode) {
          packageItem.pricePerPeriod += product.pricePerPeriod;
        }
        product.benefits.forEach(benefit => {
          benefit.amount = find(benefit.levels, level => level.isSelected).amount;
        });
      });
    });
    packageItem.price = +packageItem.price.toFixed(2);
    if (this.isListBillMode) {
      packageItem.pricePerPeriod = +packageItem.pricePerPeriod.toFixed(2);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
