<mat-expansion-panel [disabled]="!stepState.isEnabled" hideToggle="true" [(expanded)]="stepState.isOpened">
  <mat-expansion-panel-header>
    <div class="container">
      <div class="d-flex align-items-center justify-content-between">
        <mat-panel-title>
          <span>Step 3</span>
          <span class="dot-divider"></span>
          <span>{{ stepState.stepName }}</span>
        </mat-panel-title>
        @if (stepState.isCompleted) {
          <div class="complete-icon">
            <mat-icon>check</mat-icon>
          </div>
        }
      </div>
      @if (stepState.isOpened && !stepState.isCompleted) {
        <div class="step-description">Click on the pencil to adjust benefits</div>
      }
      @if (stepState.isCompleted) {
        <div class="d-flex align-items-center pt-1 step-completeness-info">
          <mat-icon class="me-2">article</mat-icon>
          <div>
            <div>{{ selectedPackageData?.name }}</div>
            <div>
              Total price <span class="text-bolder">{{ selectedPackageData?.totalPrice | priceFormat }}</span>
            </div>
          </div>
        </div>
      }
    </div>
  </mat-expansion-panel-header>
  <div class="container">
    <div class="pt-4 pb-4 benefits-page-content">
      @if (selectedPackageData) {
        <div class="d-flex mb-5 flex-wrap">
          <div class="package-title-container pe-4">
            <div class="package-title mb-2" id="packageName">{{ selectedPackageData.name }}</div>
            <div class="package-sub-title mb-2">{{ selectedPackageData.description }}</div>
            <app-plan-start-date-section
              [isReadOnly]="quoteData?.isPaymentSubmitted"
              [control]="startDateControl"
              [isUpdateImmediately]="true"
              (updateStartDateEvent)="updateStartDate($event)">
            </app-plan-start-date-section>
          </div>
          <div class="price-summary-container">
            <app-package-price-summary
              [currentPaymentMethod]="currentPaymentMethod"
              [selectedPackage]="selectedPackageData">
            </app-package-price-summary>
          </div>
        </div>
        <app-package-content
          [selectedPackage]="selectedPackageData"
          [isReadOnly]="quoteData?.isPaymentSubmitted"
          (benefitLevelChangeEvent)="onLevelOptionChange()">
        </app-package-content>
        <div class="action-links-block d-flex align-items-center justify-content-between mb-5 flex-wrap">
          <div class="left-col">
            <a class="app-link d-flex align-items-center" [href]="selectedPackageData.brochureUrl" target="_blank">
              <mat-icon class="me-1">picture_as_pdf</mat-icon>
              <span>Download Brochure</span>
            </a>
          </div>
          <div class="right-col d-flex align-items-center">
            <div class="link-container" [ngClass]="isQuoteSaved ? 'me-5' : ''">
              <a
                class="app-link d-flex align-items-center"
                href="javascript:void(0)"
                (click)="onPrintPackageClick($event)">
                <mat-icon class="me-1">local_printshop</mat-icon>
                <span>Print Package</span>
              </a>
            </div>
            @if (isQuoteSaved) {
              <div class="link-container">
                <a
                  class="app-link d-flex align-items-center"
                  [class.disabled]="!quoteData?.id"
                  href="javascript:void(0)"
                  (click)="onSharePackageClick()">
                  <mat-icon class="me-1">share</mat-icon>
                  <span>Share Package</span>
                </a>
              </div>
            }
          </div>
        </div>
        <div class="divider w-100 mb-5"></div>
        @if (!isQuoteSaved) {
          <div class="mb-5">
            <div class="mb-5 text-bolder text-big">Save this quote to continue</div>
            <div class="d-flex section-save-quote">
              <mat-form-field class="save-email-field me-4" appearance="outline">
                @if (isAgentMode) {
                  <mat-label>Enter your client's email</mat-label>
                } @else {
                  <mat-label>Enter your email</mat-label>
                }
                <input type="text" matInput [formControl]="saveQuoteEmailControl" />
                @if (saveQuoteEmailControl.errors?.required) {
                  <mat-error>Email is required.</mat-error>
                }
                @if (saveQuoteEmailControl.errors?.pattern) {
                  <mat-error>Email format does not match.</mat-error>
                }
                @if (isAgentMode) {
                  <mat-hint>This quote will be saved to your agent dashboard</mat-hint>
                } @else {
                  <mat-hint>We’ll email you a link to this page for whenever you need it.</mat-hint>
                }
              </mat-form-field>
            </div>
          </div>
          <div class="divider w-100 mb-5"></div>
        }
        <div class="mb-5 total-price-block">
          <div class="total-price mb-1">Total: {{ selectedPackageData.totalPrice | priceFormat }}/mo.</div>
          <div class="fee-price">plus one-time {{ selectedPackageData.enrolmentFee | priceFormat }} enrollment fee</div>
        </div>
        @if (!quoteData?.isPaymentSubmitted) {
          <button
            id="goToStep4Btn"
            mat-raised-button
            [disabled]="saveQuoteEmailControl && (!saveQuoteEmailControl?.value || saveQuoteEmailControl?.invalid)"
            color="primary"
            class="btn-lg next-btn"
            type="button"
            (click)="goToEnrollmentStep()">
            Go to Step 4 • Enrollment
          </button>
        }
      }
    </div>
  </div>
</mat-expansion-panel>

<iframe class="print-iframe" #printIframe></iframe>

<div class="d-none">
  <ng-template #printContent>
    <div class="print-content">
      <app-print-page-header></app-print-page-header>
      <div class="benefits-page-content">
        <div class="d-flex mb-5">
          <div class="w-50 pe-3">
            <div class="package-title mb-2">{{ selectedPackageData?.name }}</div>
            <div class="package-sub-title mb-2">{{ selectedPackageData?.description }}</div>
            <div class="text-print-default">
              Plan starts on
              <span class="text-bolder">{{ quoteData?.startDate }}</span>
              for
              <span class="text-bolder">{{ quoteData?.customers?.length }} people in {{ currentState }}.</span>
            </div>
          </div>
          <div class="w-50">
            <app-package-price-summary [selectedPackage]="selectedPackageData"></app-package-price-summary>
          </div>
        </div>
        <div>
          <app-package-content [selectedPackage]="selectedPackageData" [isReadOnly]="true"></app-package-content>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<div class="print-container">
  <ng-container *ngTemplateOutlet="printContent"></ng-container>
</div>

<ng-template #sharePackageDialog>
  <app-modal-dialog>
    <div class="d-flex flex-column align-items-center justify-content-between pb-3">
      <mat-form-field class="target-email-field" appearance="outline">
        <mat-label>Enter Target Email</mat-label>
        <input type="text" matInput [formControl]="sharePackageEmailControl" />
        @if (sharePackageEmailControl.errors?.required) {
          <mat-error>Email is required.</mat-error>
        }
        @if (sharePackageEmailControl.errors?.pattern) {
          <mat-error>Email format does not match.</mat-error>
        }
        <mat-hint>Press Enter to send.</mat-hint>
      </mat-form-field>
    </div>
  </app-modal-dialog>
</ng-template>
